export const API_AUTH = {
    login: '/api/login',
    register: '/api/register',
    resetPassword: '/api/reset-password',
    changePassword: '/api/reset-password/change-password',
    forgottenPassword: '/api/reset-password/forgotten-password',
    verify: '/api/register/confirm',
    refreshToken: '/api/login/refresh-token'
};

export const API_USERS_ADMINISTRATION = {
    allAdmins: '/api/user-administration/admins',
    allTenants: '/api/user-administration/tenants',
    getByUuid: '/api/user-administration/uuid',
    update: '/api/user-administration/update',
    tenantDetails: '/api/user-administration/tenant-details',
    happyBirthdays: '/api/user-administration/happy-birthdays',
    tenantsHappyBirthdays: '/api/user-administration/tenants-happy-birthday',
    sendHappyBirthdayMessage: '/api/user-administration/send-happy-birthday-message',
};

export const API_COMPANY = {
    add: '/api/company/add',
    update: '/api/company/update',
    all: '/api/company',
    byUuid: '/api/company/get-by-uuid'
};

export const API_BILLING_SETTINGS = {
    all: '/api/billing-settings',
    add: '/api/billing-settings/add',
    update: '/api/billing-settings/update',
    defaultSeries: '/api/billing-settings/update-default-series',
    delete: '/api/billing-settings/delete',
    addBankAccounts: '/api/billing-settings/add-bank-accounts',
    getBankAccounts: '/api/billing-settings/bank-accounts',
};

export const API_BANK_ACCOUNT = {
    get: '/api/bank-accounts',
    add: '/api/bank-accounts/add',
    edit: '/api/bank-accounts/update'
};

export const API_EXCHANGE = {
    get: '/api/exchange-rate'
};

export const API_DEPARTMENT = {
    all: '/api/department/all',
    allEmployees: '/api/department/all-employees',
    add: '/api/department/add',
    addEmployees: '/api/department/add-employees',
    update: '/api/department/update',
};

export const API_INVENTORY = {
    allObjects: '/api/inventory/all',
    allCategory: '/api/inventory/all-category',
    addCategory: '/api/inventory/add-category-inventory',
    editCategory: '/api/inventory/edit-category-inventory',
    addInventoryItem: '/api/inventory/add-inventory-item',
    editInventoryItem: '/api/inventory/edit-inventory-item',
    allBuildings: '/api/inventory/all-buildings',
    deleteObject: '/api/inventory/delete',
    moveObject: '/api/inventory/move-object',
    movementHistory: '/api/inventory/history',
};

export const API_INVITATION = {
    all: '/api/invitation/all',
    generate: '/api/invitation',
    delete: '/api/invitation/delete',
};

export const API_RENTAL_REQUESTS = {
    all: '/api/rental-request/all',
    byId: '/api/rental-request/by-id',
    delete: '/api/rental-request/delete',
    update: '/api/rental-request/update',
    approve: '/api/rental-request/approve',
    add: '/api/rental-request'
};

export const API_BUILDINGS = {
    allPaginated: '/api/buildings/all-paginated',
    all: '/api/buildings/all',
    prices: '/api/buildings/prices',
    addPrice: '/api/buildings/add-price',
    byCompany: '/api/buildings/by-company',
    byId: '/api/buildings/by-id',
    delete: '/api/buildings/delete',
    update: '/api/buildings/update',
    add: '/api/buildings',
    checkBuildingPrice: '/api/buildings/check-price-history'
};

export const API_APARTMENTS = {
    allPaginated: '/api/apartments/all-paginated',
    exportXlsx: '/api/apartments/export-xlsx',
    allAvailableByBuilding: '/api/apartments/all-available-by-building',
    allInventoryItems: '/api/apartments/all-inventory-items',
    floorsByBuildingId: '/api/apartments/floors-by-building-id',
    byBuilding: '/api/apartments/by-building',
    tenants: '/api/apartments/tenants',
    byId: '/api/apartments/by-id',
    delete: '/api/apartments/delete',
    update: '/api/apartments/update',
    add: '/api/apartments'
};

export const API_RETAILS = {
    add: '/api/retails/add',
    edit: '/api/retails/edit',
    all: '/api/retails/all',
};

export const API_CONTRACT = {
    all: '/api/contract/all',
    update: '/api/contract/update',
    financeSettings: '/api/contract/finance-settings',
    deactivateFinanceSettings: '/api/contract/deactivate-finance-setting',
    add: '/api/contract/add-contract',
    closeContract: '/api/contract/close-contract',
    closeAdditionalAct: '/api/additional-act/close',
    updateAdditionalAct: '/api/additional-act/update',
    personalData: '/api/contract/personal-data',
    generateAdditionalAct: '/api/additional-act/add',
    allAdditionalAct: '/api/additional-act/all',
    allAdditionalActExpired: '/api/additional-act/expired',
    changeCompanyOnContract: '/api/contract/change-company',
    expired: '/api/contract/expired',
    expiredSettings: '/api/contract/expired-settings',
    exportExpiredContracts: '/api/contract/export-expired-contract',
    sendNotification: '/api/contract/send-notification',
    exportExpiredAdditionalActs: '/api/additional-act/export-expired-additional-acts',
    exportActiveContracts: '/api/contract/export-active-contracts',
    sendNotificationAdditionalAct: '/api/additional-act/send-notification',
};

export const API_THIRD_PARTY = {
    add: '/api/third-party-connections/add',
    all: '/api/third-party-connections/all',
    newAccessTokenSPV: '/api/third-party-connections/new-access-token-spv',
    newRefreshTokenSPV: '/api/third-party-connections/new-refresh-token',
    vatRegister: '/api/third-party-connections/vat-register',
    updateOAuthCredentials: '/api/third-party-connections/update-oauth-credentials',
};

export const API_INVOICE = {
    all: '/api/invoices/all',
    add: '/api/invoices/add',
    edit: '/api/invoices/edit',
    getByUuid: '/api/invoices/uuid',
    viewInvoiceByAdmin: '/api/invoices/view-invoice',
    viewInvoiceByUser: '/api/view-invoice',
    collectInvoice: '/api/invoices/collect-invoice',
    reports: '/api/invoices/reports',
    reportWeeklyBills: '/api/invoices/report-weekly-bills',
    reportMonthlyBills: '/api/invoices/report-monthly-bills',
    inQueue: '/api/invoices/in-queue',
    sendInQueueInvoices: '/api/invoices/send-in-queue-invoices',
    paymentsHistory: '/api/invoices/payments-history',
    paymentsHistoryByDate: '/api/invoices/payments-history-by-date',
    sendReminder: '/api/invoices/send-reminder',
    distinctCustomers: '/api/invoices/distinct-customers',
    clients: '/api/invoices/clients',
    exportDataXlSX: '/api/invoices/export-data-xlsx',
    exportInvoicesXlSX: '/api/invoices/export-invoices-xlsx',
    exportProformaInvoices: '/api/invoices/export-proforma-invoices',
    exportDataXML: '/api/invoices/export-data-xml',
    exportEInvoicing: '/api/invoices/export-e-invoicing',
    exportDataPDF: '/api/invoices/export-data-pdf',
    exportDataPDFClient: '/api/invoices/export-data-pdf-by-client',
    addClientCode: '/api/invoices/add-client-code',
    updateClientData: '/api/invoices/update-client-data',
    sendViaEmail: '/api/invoices/send-invoice-via-email',
    uploadSpv: '/api/invoices/upload-spv',
    allEInvoicesLog: '/api/invoices/eInvoice-logs',
    eInvoiceState: '/api/invoices/eInvoice-state',
    saveZipSentInvoice: '/api/invoices/save-zip-sent-invoice',
    calculateInvoices: '/api/invoices/calculate-invoices-value',
    generateProformaInvoices: '/api/invoices/generate-proforma',
    generateInvoicesMonthly: '/api/invoices/generate-invoices-monthly',
    withoutPenalties: '/api/invoices/without-penalties',
    sendProformaInvoices: '/api/invoices/send-proforma-invoices',
    deletePayment: '/api/invoices/delete-payment',
    deleteInvoice: '/api/invoices/delete-invoice-in-queue',
    editInvoiceItem: '/api/invoices/edit-invoice-item-code',
    reInvoicing: '/api/invoices/re-invoicing'
};

export const API_INVOICE_ITEM_CODE = {
    all: '/api/invoice-item-code/all',
    add: '/api/invoice-item-code/add',
    edit: '/api/invoice-item-code/edit',
}

export const API_EXPENSE = {
    allExpenses: '/api/expense/all',
    totalExpenses: '/api/expense/total-expenses',
    syncSpv: '/api/expense/sync-spv',
    generatePdf: '/api/expense/generate-pdf',
};

export const API_RECEIPT = {
    all: '/api/receipts',
    add: '/api/receipts',
    viewReceipt: '/api/receipts/view-receipt',
};

export const API_TICKET = {
    addTicket: '/api/tickets/add',
    addTicketFromContract: '/api/tickets/add-ticket-from-contract',
    updateTicket: '/api/tickets/update',
    deleteTicket: '/api/tickets/delete',
    addServiceItem: '/api/tickets/service-item/add',
    editServiceItem: '/api/tickets/service-item/update',
    deleteServiceItem: '/api/tickets/service-item/delete',
    allServiceItems: '/api/tickets/service-item/all',
    allTicket: '/api/tickets/all',
    allTicketToBeBilled: '/api/tickets/all-to-be-billed',
    statistics: '/api/tickets/statistics',
    exportTickets: '/api/tickets/export-tickets',
};

export const API_UTILITY = {
    getAll: '/api/utility',
    add: '/api/utility',
    stats: '/api/utility/stats',
    editUtility: '/api/utility/edit-utility',
    calculateHeating: '/api/utility/calculate-hot-water-and-heating',
    getAvailableUtilityDates: "/api/utility/available-utilities",
    floorsByBuilding: '/api/utility/floors-by-building',
    noticeBoardExport: '/api/utility/export-notice-board',
    utilitiesExport: '/api/utility/export-utilities',
    serviceMeter: '/api/utility/service-meter',
    prevMonthIdx: '/api/utility/prev-month-index',
    revertCostChanges: '/api/utility/revert-cost-changes'
};

export const API_UTILITY_ACTUAL_PRICES = {
    add: '/api/utility-real-prices-consumption/add',
    edit: '/api/utility-real-prices-consumption/edit',
    all: '/api/utility-real-prices-consumption/all',
    report: '/api/utility-real-prices-consumption/report',
}

export const API_GUEST = {
    all: '/api/guests/all',
    add: '/api/guests/add',
    edit: '/api/guests/edit',
};

export const API_REPORT = {
    dashboard: '/api/reports/dashboard',
    averageRent: '/api/reports/average-rent',
    tenantsBaseOnAge: '/api/reports/tenants-base-on-age',
    tenantsBaseOnCitizenship: '/api/reports/based-on-citizenship',
    tenantsBaseOnOccupation: '/api/reports/based-on-occupation',
    tenantsBasedOnStayPeriod: '/api/reports/based-on-stay-period',
    collectedOverview: '/api/reports/collected-overview',
    ticketReports: '/api/reports/report-tickets-weekly',
    ticketReportsYearly: '/api/reports/report-tickets-yearly',
    leadConversion: '/api/reports/lead-conversion',
    latestTransactions: '/api/reports/latest-transactions',
    topSales: '/api/reports/top-sales',
    utilityReportBilled: '/api/reports/report-utility-billed',
    utilityReportConsumption: '/api/reports/report-utility-consumption',
    utilityReportAverageConsumption: '/api/reports/report-utility-average-consumption',
};

export const API_EMAIL_NOTIFICATION = {
    sendBulkNotification: '/api/email-notification/send-bulk'
};

export const API_ACCESS_RIGHT = {
    add: '/api/v1/access-right/add',
    byUuid: '/api/v1/access-right/by-uuid',
};

export const API_PARKING = {
    availableSpaces: '/api/parking/total-available-spaces',
    add: '/api/parking/add',
    update: '/api/parking/update',
    all: '/api/parking/all',
};

export const API_SPV_MESSAGE = {
    all: '/api/spv-messages/all',
    syncSpv: '/api/spv-messages/sync-spv',
    readMessage: '/api/spv-messages/read-message'
};

export const API_APP_SETTINGS = {
    addUpdate: '/api/app-settings/add-update',
    all: '/api/app-settings/all',
}

