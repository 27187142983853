import React, {useEffect, useState} from "react";
import Modal, {ModalBody, ModalHeader} from "../../../bootstrap/Modal";
import {CardLabel, CardTitle} from "../../../bootstrap/Card";
import useDarkMode from "../../../../hooks/useDarkMode";
import Badge from "../../../bootstrap/Badge";
import {getDateOfBirth} from "../../../../utils/dateFormater";
import jwtInstance from "../../../../guards/jwt.interceptor";
import {API_USERS_ADMINISTRATION} from "../../../../utils/restApi";
import {errorResponse, successResponse} from "../../../../utils/getResponse";
import Popovers from "../../../bootstrap/Popovers";
import {useDispatch, useSelector} from "../../../../redux/store";
import {updateUser} from "../../../../redux/slices/user";

const TenantHappyBirthdayModal = ({isOpen, setIsOpen}: any) => {
    const [users, setUsers] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {isUpdated} = useSelector((state: any) => state.user);
    const {darkModeStatus} = useDarkMode();
    const dispatch = useDispatch();
    useEffect(() => {
        jwtInstance.get(API_USERS_ADMINISTRATION.tenantsHappyBirthdays)
            .then(response => {
                if (response.status === 200) {
                    setUsers(response.data);
                }
            }).catch(error => {
            errorResponse(error, 'Error', 'danger');
        })
    }, [isUpdated]);

    const sendMessage = (userUuid: any) => {
        setIsLoading(true);
        jwtInstance.post(API_USERS_ADMINISTRATION.sendHappyBirthdayMessage, null, {params: {userUuid: userUuid}})
            .then(response => {
                if (response.status === 200) {
                    successResponse(response, 'Send message', 'success');
                    dispatch(updateUser(!isUpdated));
                    setIsLoading(false);
                }
            }).catch(error => {
                setIsLoading(false);
                errorResponse(error, 'Error', 'danger');
        })
    }
    return (
        <Modal
            id={"BirthdayModal"}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isStaticBackdrop
            isScrollable={true}
            size={'lg'}
        >
            <ModalHeader className={"mx-2"} setIsOpen={() => setIsOpen(false)}>
                <CardLabel icon={"HolidayVillage"} iconColor={darkModeStatus ? "light" : "dark"}>
                    <CardTitle style={{fontWeight: 500}}>{`Happy birthdays`}</CardTitle>
                </CardLabel>
            </ModalHeader>
            <ModalBody>
                <table className={`table table-hover d-none d-md-none d-lg-none d-xl-table`}>
                    <thead style={{
                        boxShadow: "-1px 0px 23px -10px rgba(0,0,0,0.58)",
                        borderRadius: "5px",
                        fontWeight: 500,
                        fontSize: "1.1rem"
                    }}>

                    <tr style={{border: "1px solid transparent"}}>
                        <td>
                            <span style={{marginLeft: 40}}>Name</span>
                        </td>
                        <td className={"text-center"}>Age</td>
                        <td>Email</td>
                        <td className={"text-center"}>Actions</td>
                    </tr>
                    </thead>
                    <tbody style={{fontWeight: 500, fontSize: 12}}>
                    {users?.map((user: any, index: number) => (
                        <tr key={index}>
                            <td>
                                <div className={'d-flex'}>
                                    <img
                                        srcSet={"./invite.svg"}
                                        alt={"company"}
                                        width="25"
                                        height="25"
                                    />
                                    <div style={{textAlign: 'left', marginLeft: 10}}>
                                        <span className={"text-nowrap"}>{`${user?.lastName} ${user?.firstName}`}</span>
                                        <div className={'fw-bold text-muted'} style={{
                                            fontSize: 9,
                                            lineHeight: 0.9,
                                            textTransform: 'uppercase'
                                        }}>{user?.tenantType || '-'}</div>
                                    </div>
                                </div>
                            </td>
                            <td className={'text-center'}>
                                <Badge color={'info'} isLight shadow={'sm'} style={{fontSize: 11}}>
                                    {`${user?.age || 0} years `}
                                    <span className={'text-primary'}>
                                            <i className={`fa-duotone fa-cake-candles ${darkModeStatus ? 'text-light' : 'text-primary'}`}
                                               style={{
                                                   marginInline: 5,
                                                   fontSize: 16,
                                               }}>
                                            </i>
                                        {getDateOfBirth(new Date(user?.dateOfBirth))}
                                        </span>
                                </Badge>
                            </td>
                            <td>
                                <span className={"text-nowrap"}>{user?.email}</span>
                                <div className={"text-nowrap"}>{user?.phoneNumber}</div>
                            </td>
                            <td className={'text-center'}>
                                {user?.happyBirthdayMessageSent ? (
                                    <i className="fa-solid fa-check fs-5 text-success"></i>
                                ) : (<Popovers trigger={'hover'} title={'Send message'} placement={'left'}>
                                    {isLoading ? 'Wait, message is sending...' : (<i onClick={() => sendMessage(user?.uuid)}
                                                                            className="fa-duotone fa-solid fa-paper-plane text-info fs-5 cursor-pointer"></i>)}
                                </Popovers>)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </ModalBody>
        </Modal>
    )
};

export default TenantHappyBirthdayModal;
