import React, {useEffect, useState} from 'react';
import Header, {HeaderLeft, HeaderRight} from '../../../layout/Header/Header';
import CommonHeaderRight from "./CommonHeaderRight";
import Button from "../../../components/bootstrap/Button";
import jwtInstance from "../../../guards/jwt.interceptor";
import {API_USERS_ADMINISTRATION} from "../../../utils/restApi";
import {errorResponse} from "../../../utils/getResponse";
import TenantHappyBirthdayModal from "../../../components/dashboard/app-administration/users-app/TenantHappyBirthdayModal";

const DashboardHeader = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [countDays, setCountDays] = useState<number>(0);
    useEffect(() => {
        jwtInstance.get(API_USERS_ADMINISTRATION.happyBirthdays)
            .then(response => {
                if (response.status === 200) {
                    setCountDays(response.data);
                }
            }).catch(error => {
                errorResponse(error, 'Error', 'danger');
        })
    }, []);
    return (
        <>
            <Header>
                <HeaderLeft>
                    <Button
                        onClick={() => setIsOpen(true)}
                        icon={'fa-solid fa-cake-candles'}
                        size={'sm'}
                        isLight
                        color={countDays > 0 ? 'danger' : 'light'}>
                        {countDays > 0 ? `See birthdays (${countDays} today)` : 'No birthdays found'}
                    </Button>
                </HeaderLeft>
                <HeaderRight>
                    <CommonHeaderRight/>
                </HeaderRight>
            </Header>
            <TenantHappyBirthdayModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};

export default DashboardHeader;
